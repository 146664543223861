import AppIcon from 'src/components/elements/icons/AppIcon'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {useCurrentLocale} from 'src/hooks/locale'

interface InfoBoxProps {
  iconName: string
  title_en: string
  subtitle_en: string
  title_fr: string
  subtitle_fr: string
}

function InfoBox(props: InfoBoxProps) {
  const {title_en, subtitle_en, title_fr, subtitle_fr, iconName} = props

  const currentLocale = useCurrentLocale()

  return (
    <div className="flex flex-col justify-center items-center">
      <div>
        <AppIcon name={iconName} />
      </div>
      <AppTypography
        variant="displayMedium"
        neutralColor={900}
        className="pt-3 text-center"
        component="h2"
      >
        {currentLocale === 'fr' ? title_fr : title_en}
      </AppTypography>
      <AppTypography
        className="text-text-secondary text-center pt-1"
        component="p"
        variant="body"
      >
        {currentLocale === 'fr' ? subtitle_fr : subtitle_en}
      </AppTypography>
    </div>
  )
}

export default InfoBox
